import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/admin": [3],
		"/admin/exchanges": [~4],
		"/admin/exchanges/new": [~6],
		"/admin/exchanges/[id]/edit": [~5],
		"/admin/notifications": [~7],
		"/admin/notifications/new": [~9],
		"/admin/notifications/[id]/edit": [~8],
		"/backtests": [~10],
		"/backtests/new": [~13],
		"/backtests/[id]": [~11],
		"/backtests/[id]/edit": [~12],
		"/login": [~14],
		"/login/pwd": [~15],
		"/notifications/[id]": [~16],
		"/playground": [17],
		"/playground/api/backend": [18],
		"/playground/api/backend/backtest": [19],
		"/playground/i18n": [20],
		"/playground/instruments-select": [21],
		"/playground/markdown-editor": [22],
		"/playground/money-counterpart": [23],
		"/playground/paginator": [24],
		"/playground/shadcn": [25],
		"/playground/strategy-select": [26],
		"/playground/ui": [27],
		"/settings": [~28],
		"/strategies": [~29],
		"/strategies/new": [~32],
		"/strategies/[id]": [~30],
		"/strategies/[id]/edit": [~31]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';